<template>
  <v-dialog v-model="modalOpen" max-width="630px" class="DeviceDetailsModal">
    <v-card outlined>
      <v-card-title class="DeviceDetailsModal__title">
        <v-row>
          <v-col
            cols="auto"
            class="mr-auto"
          >
            Device Information
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="DeviceDetailsModal__content">
        <div v-if="deviceLoading" class="py-12 text-center">
          <v-progress-circular
            :size="70"
            color="primary"
            indeterminate
          />
        </div>

        <div v-else>
          <v-row>
            <v-col>
              <p class="DeviceDetailsModal__label text-subtitle-2">Device Name</p>
              <p class="DeviceDetailsModal__value text-body-2">{{device.name || '-'}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="DeviceDetailsModal__label text-subtitle-2">Unique ID</p>
              <p class="DeviceDetailsModal__value text-body-2">{{device.uid || '-'}}</p>
            </v-col>
            <v-col>
              <p class="DeviceDetailsModal__label text-subtitle-2">Last response</p>
              <p class="DeviceDetailsModal__value text-body-2">{{ formattedLastResponse }} ({{ responseTilNow }})</p>
            </v-col>
          </v-row>

          <v-row>
            <!-- TODO: deal with status here -->
            <!-- for status -->
            <v-col cols="12" v-if="deviceStatusLoading">
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </v-col>
            <v-col cols="12" v-else-if="!deviceStatusLoading && deviceHasStatus">
              table here
            </v-col>
            <v-col cols="12" v-else>
              no data here
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col cols="5" class="d-flex justify-space-between">
            <v-btn
              color="primary"
              small
              plain
              :disabled="deviceLoading"
              @click="copyToken"
            >
              Copy Token
            </v-btn>
            <v-btn
              plain
              small
              :disabled="deviceLoading || deviceStatusLoading"
              @click="getDeviceStatus"
            >
              Get Status
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment-timezone";
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: devicesGetters, mapActions: devicesActions } = createNamespacedHelpers('devices');
const { mapActions: notificationActions } = createNamespacedHelpers('notifications');

export default {
  name: 'DeviceDetailsModal',
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showStatus: false
    };
  },
  computed: {
    ...devicesGetters({
      device: 'SELECTED_DEVICE',
      deviceStatus: 'SELECTED_DEVICE_STATUS',
      deviceHasStatus: 'SELECTED_DEVICE_HAS_STATUS',
      deviceLoading: 'SELECTED_DEVICE_LOADING',
      deviceStatusLoading: 'SELECTED_DEVICE_STATUS_LOADING',
    }),
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    formattedLastResponse() {
      if (!this.device || !this.device.last_response_date) {
        return "-";
      }

      const localTZ = moment.tz.guess();
      return moment(this.device.last_response_date)
        .tz(localTZ)
        .format("L HH:mm");
    },
    responseTilNow() {
      if (!this.device || !this.device.last_response_date) {
       return "-";
      }

      return moment(this.device.last_response_date).fromNow();
    },
  },
  methods: {
    ...devicesActions(['fetchDeviceDetails', 'clearSelectedDevice', 'fetchDeviceStatus']),
    ...notificationActions(['pushNotification']),
    close() {
      this.modalOpen = false;
    },
    async copyToken() {
      await navigator.clipboard.writeText(this.device.token);
      this.pushNotification('Token has been successfully copied to the clipboard');
    },
    getDeviceStatus() {
      this.fetchDeviceStatus(this.device);
    },
  },
  mounted() {
    this.fetchDeviceDetails({ uid: this.deviceId });
  },
  beforeDestroy() {
    this.clearSelectedDevice();
  }
}
</script>

<style lang="scss" scoped>
@import './device-details-modal';
</style>
