<template>
  <v-dialog v-model="modalOpen" max-width="630px" class="AddEditDeviceModal">
    <v-card outlined>
      <v-card-title class="AddEditDeviceModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            {{ isEdit ? "Edit Device" : "Add new Device" }}
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="AddEditDeviceModal__content">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Device Name"
                >
                  <v-text-field
                    label="Device Name"
                    name="deviceName"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.name"
                    :loading="deviceLoading || addUpdateDeviceLoading"
                    :disabled="deviceLoading || addUpdateDeviceLoading"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="deviceLoading || addUpdateDeviceLoading"
                >
                  {{ isEdit ? "Edit Device" : "Add Device" }}
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: devicesGetters, mapActions: devicesActions } =
  createNamespacedHelpers("devices");

const { mapGetters: notificationsGetters } = createNamespacedHelpers('notifications');

export default {
  name: "AddEditDeviceModal",
  props: {
    deviceId: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...devicesGetters({
      device: "SELECTED_DEVICE",
      deviceLoading: "SELECTED_DEVICE_LOADING",
      addUpdateDeviceLoading: "ADD_UPDATE_DEVICE_LOADING",
    }),
    ...notificationsGetters({ hasError: 'HAS_ERROR' }),
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isEdit() {
      return Boolean(this.deviceId);
    },
  },
  methods: {
    ...devicesActions(["fetchDeviceDetails", "clearSelectedDevice", "addDevice", "updateDevice"]),
    close() {
      this.modalOpen = false;
    },
    onSubmit() {
      if (this.isEdit) {
        this.updateDevice({
          uid: this.deviceId,
          settings: {
            name: this.formData.name,
          },
        });
      } else {
        this.addDevice({
          name: this.formData.name,
        });
      }
    },
  },
  watch: {
    addUpdateDeviceLoading(value) {
      if (!value && !this.hasError) {
        this.close();
      }
    },
  },
  async mounted() {
    if (this.deviceId) {
      await this.fetchDeviceDetails({ uid: this.deviceId });

      this.formData = { ...this.device };
    }
  },
  beforeDestroy() {
    this.clearSelectedDevice();
  },
};
</script>

<style lang="scss" scoped>
@import "./add-edit-device-modal";
</style>
