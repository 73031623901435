<template>
  <div class="Devices">
    <v-row class="Devices__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="openAddEditDevice()">
          + Add Bot
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="Devices__data-table elevation-0"
      :items="devices"
      :headers="headers"
      item-key="id"
      outlined
      :loading="devicesLoading"
    >
      <template #item.status="{ item }">
        <v-badge
          v-if="checkActivity(item.last_response_date)"
          color="success"
          inline
          dot
        >
          Active
        </v-badge>
        <v-badge v-else color="error" inline dot> Inactive </v-badge>
      </template>
      <template #item.actions="{ item }">
        <v-btn icon color="primary" @click.stop="openDeviceDetails(item)">
          <v-icon small> mdi-magnify </v-icon>
        </v-btn>
        <v-btn icon color="primary" @click.stop="openAddEditDevice(item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon color="error" @click.stop="removeDevice(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <device-details-modal
      v-model="showDeviceDetails"
      :device-id="selectedDeviceId"
      v-if="showDeviceDetails"
    />
    <add-edit-device-modal
      v-model="showAddEditModal"
      :device-id="selectedDeviceId"
      v-if="showAddEditModal"
    />
    <delete-prompt-modal
      v-model="showDeletePrompt"
      :device-id="selectedDeviceId"
      v-if="showDeletePrompt"
    />
  </div>
</template>

<script>
import * as moment from "moment";
import { createNamespacedHelpers } from "vuex";

import DeviceDetailsModal from "./modals/device-details-modal/device-details-modal.vue";
import AddEditDeviceModal from "./modals/add-edit-device-modal/add-edit-device-modal.vue";
import DeletePromptModal from "./modals/delete-prompt-modal/delete-prompt-modal.vue";

const { mapActions: devicesActions, mapGetters: deviceGetters } =
  createNamespacedHelpers("devices");
const { mapGetters: customerGetters } = createNamespacedHelpers("customer");

export default {
  name: "Devices",
  components: {
    DeletePromptModal,
    DeviceDetailsModal,
    AddEditDeviceModal,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "UID", value: "uid" },
        { text: "Status", value: "status" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      selectedDeviceId: "",
      showDeviceDetails: false,
      showAddEditModal: false,
      showDeletePrompt: false,
    };
  },
  methods: {
    ...devicesActions(["fetchDevices", "deleteDevice"]),
    openDeviceDetails({ uid }) {
      this.selectedDeviceId = uid;
      this.showDeviceDetails = true;
    },
    openAddEditDevice({ uid = "" } = {}) {
      this.selectedDeviceId = uid;
      this.showAddEditModal = true;
    },
    removeDevice({ uid }) {
      this.selectedDeviceId = uid;
      this.showDeletePrompt = true;
    },
    checkActivity(item) {
      const border = moment().subtract(2, "hours");
      return moment(item).isSameOrAfter(border);
    },
  },
  watch: {
    "selectedCustomer.customer_id"() {
      this.fetchDevices();
    },
  },
  computed: {
    ...deviceGetters({ devices: "DEVICES", devicesLoading: "DEVICES_LOADING" }),
    ...customerGetters({
      customer: "CUSTOMER",
      user: "USER",
      selectedCustomer: "SELECTED_CUSTOMER",
    }),
    is_privileged() {
      return this.user && this.user.is_privileged;
    },
    sh_admin() {
      return this.customer.customer_id === 0;
    },
  },
  mounted() {
    this.fetchDevices();
  },
};
</script>

<style lang="scss" scoped>
@import "./devices";
</style>
